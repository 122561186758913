import React from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/Layout"
import Head from "../components/Head"
import appStoreImage from "../images/ethio app store.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <div
        style={{
          background: "white",
          padding: "2rem 1rem",
          textAlign: "center",
          minWidth: "350px",
          minHeight: "75vh"
        }}
      >
        <Head title="Home" />
        <h1>ethio telecom</h1>
        <h3>Welcome to ethio App Store</h3>
        <div>
          <Link to="/apps">
            {/* <Link to="/"> */}
            <img
              src={appStoreImage}
              alt="ethio app store"
              width="350px"
              height="300px"
            ></img>
          </Link>
        </div>
        <p style={{ marginTop: "2.5rem" }}>
          Need to keep in touch with us <Link to="/contact">Contact</Link>
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
